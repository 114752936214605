// SET global classes.
.ui- {
  &-app {
    &-wrapper {
      @apply mx-auto grid min-h-screen max-w-2k overflow-x-hidden bg-theme-light text-theme;
      grid-template-rows: 1fr auto;
    }
  }

  &-router {
    &-view {
      .swiper {
        &-wrapper {
          transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &-slide {
          &-prev,
          &-next {
            .swiper-card {
            }
          }

          &-active {
            .swiper-card {
            }
          }
        }

        &-button {
          &-prev,
          &-next {
            @apply text-white/75 hover:text-white;
            transform: translateY(-1.5rem);
          }
        }

        &-pagination {
          &-bullets {
            @apply bottom-0;
          }

          &-bullet {
            @apply bg-theme-200;

            &-active {
              @apply bg-theme;
            }
          }
        }
      }
    }
  }

  &-footer {
    .as-links {
      @apply max-lg:grid max-lg:grid-cols-[repeat(auto-fill,minmax(140px,1fr))] max-lg:gap-8;

      .link {
        @apply mb-2 block w-fit text-sm capitalize text-theme/80 hover:text-theme sm:mb-3;
      }
    }

    .as-touch {
    }
  }

  &-loader {
    &-wrapper {
      @apply fixed inset-0 z-[992] max-sm:flex max-sm:items-end;
    }
  }

  &-rainbow {
    background-image: linear-gradient(
      to right,
      #ef5350,
      #f48fb1,
      #7e57c2,
      #2196f3,
      #26c6da,
      #43a047,
      #eeff41,
      #f9a825,
      #ff5722
    );
  }
}
