.ui--dialogs {
  &-wrapper {
    @apply fixed inset-0;
    z-index: 1000;

    &:not(.active) {
      pointer-events: none !important;

      [class$='-backdrop'] {
        @apply opacity-0;
      }
    }
  }

  &-backdrop {
    @apply fixed inset-0 bg-black/25;
  }

  &-list {
    @apply absolute inset-0 z-10 grid p-4;
  }

  &-context {
    @apply absolute;

    &.as-alert {
      @apply m-8 self-start justify-self-center rounded-2xl bg-theme-light p-6;
      min-width: 320px;
      max-width: 480px;
    }

    &.as-confirm {
      @apply m-8 self-start justify-self-center rounded-2xl bg-black/75 p-6 ring-2 ring-theme-light/10 backdrop-blur-lg;
      min-width: 320px;
      max-width: 480px;
    }

    &.as-modal {
      @apply m-8 justify-self-center rounded-2xl bg-black/80 ring-2 ring-theme-light/10 backdrop-blur-lg;
      max-width: 720px;

      &.start {
        @apply self-start;
      }

      &.center {
        @apply self-center;
      }
    }
  }

  &-alert {
    &.as-header {
      @apply pb-4;

      .h4::before {
        content: '\01F4E2';
        font-style: normal;
        margin-right: 0.35rem;
      }
    }

    &.as-body {
      @apply border-t-2 border-theme-200/20 pb-6 pt-4 text-black;
    }

    &.as-footer {
      @apply flex justify-end gap-4;

      .btn {
        @apply h-8 rounded-xl px-6;

        &:focus {
          @apply outline outline-1 outline-offset-2 outline-theme-light/30;
        }

        &-ok {
          @apply bg-theme text-white;
        }
      }
    }
  }

  &-confirm {
    &.as-header {
      @apply pb-4;

      .h4::before {
        content: '\01F4E2';
        font-style: normal;
        margin-right: 0.35rem;
      }
    }

    &.as-body {
      @apply border-t-2 border-theme-light/5 pb-6 pt-4 text-theme-light/90;

      .input {
        @apply mt-4 h-9 rounded bg-theme-light/[0.04] px-3;

        &:focus {
          @apply outline outline-1 outline-offset-2 outline-theme-light/20;
        }
      }
    }

    &.as-footer {
      @apply flex justify-end gap-4;

      .btn {
        @apply h-7;

        &-confirm {
          @apply bg-theme-light px-4 text-white;

          &:focus {
            @apply outline outline-1 outline-offset-2 outline-theme-light/30;
          }
        }

        &-cancel {
          @apply px-2 text-theme-light/60 hover:text-theme-light;
        }
      }
    }
  }

  &-modal {
  }
}
