.btn {
  @apply relative grid grid-flow-col items-center justify-center gap-1 leading-5 duration-150 ease-out;

  * {
    @apply pointer-events-none;
  }

  &-text {
    &:hover {
      .text {
        @apply underline;
      }
    }
  }

  &-primary {
    @apply relative overflow-hidden text-theme-light ring-1 ring-theme-light hover:text-theme hover:before:w-full;

    &::before {
      @apply absolute h-full w-0 bg-theme-light transition-all;
      content: '';
    }
  }

  &-secondary {
    @apply bg-theme text-theme-light hover:text-white;
  }

  &:disabled,
  &.disabled {
    @apply cursor-not-allowed opacity-50;
  }
}
