html,
body {
  @apply scroll-smooth bg-theme-light font-primary antialiased;
  background-image: linear-gradient(rgba(#f1e2ce, 0.15) 2px, transparent 2px),
    linear-gradient(90deg, rgba(#f1e2ce, 0.15) 2px, transparent 2px),
    linear-gradient(rgba(#f1e2ce, 0.25) 1px, transparent 1px),
    linear-gradient(90deg, rgba(#f1e2ce, 0.25) 1px, transparent 1px);
  background-size:
    100px 100px,
    100px 100px,
    20px 20px,
    20px 20px;
  background-position:
    -2px -2px,
    -2px -2px,
    -1px -1px,
    -1px -1px;
  background-attachment: fixed;
}

a {
  @apply inline-block cursor-pointer select-none text-inherit no-underline outline-none;
}

img {
  @apply pointer-events-none select-none;
}

label {
  @apply select-none;
}

button,
input,
select,
textarea {
  @apply border-none bg-transparent font-primary shadow-none outline-none first-line:text-inherit;
}

button {
  @apply select-none;
}

input,
select,
textarea {
  @apply w-full;
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @apply m-0 appearance-none;
  }

  &[type='number'] {
    appearance: textfield;
  }
}
