.ui--toast {
  &-wrapper {
    @apply fixed left-1/2 top-20 -translate-x-1/2;
    z-index: 2000;

    &:not(.active) {
      pointer-events: none !important;
    }
  }

  &-item {
    @apply pointer-events-auto relative w-fit min-w-64 max-w-96 -translate-y-1 overflow-hidden rounded-xl bg-black/50 opacity-0 ring-2 ring-white/[0.08] backdrop-blur-lg;
    grid-template-columns: 40px 1fr;

    &:not(:last-child) {
      @apply mb-2;
    }
  }
}
