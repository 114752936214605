.ui--navbar {
  @apply fixed inset-x-0 top-0 z-40 lg:absolute;

  &-container {
    @apply relative;

    &.for-lg {
      @apply container relative border-b-2 border-white/10 px-8 py-2 text-white max-lg:hidden;
    }

    &.for-md {
      @apply bg-theme-light shadow-2xl lg:hidden;

      .context {
        @apply grid h-14 grid-cols-3 items-center justify-between px-2;
      }
    }
  }

  &-grid {
    @apply grid items-center gap-8;
    grid-template-columns: 1fr 120px 1fr;

    .nav-link {
      @apply relative flex h-20 items-center text-nowrap px-1 text-sm font-medium uppercase text-white/80 hover:text-white;

      &.active {
        @apply text-white;

        &::after {
          @apply absolute inset-x-0 -bottom-2.5 h-1 bg-white content-[''];
        }
      }
    }

    .columns {
      @apply gap-2;
    }

    .dropdown {
      &-wrapper {
        &:hover,
        &:focus-within {
          .nav-link:not(.dropdown-link) {
            @apply text-white;
          }

          .dropdown-context {
            @apply pointer-events-auto translate-y-0 opacity-100;
          }
        }
      }

      &-context {
        @apply pointer-events-none absolute z-10 translate-y-1 pt-3 opacity-0 transition-all;
      }

      &-inner {
        @apply rounded-sm bg-white/90 p-2 outline-1 outline-offset-0 outline-white/90;

        &::-webkit-scrollbar {
          @apply size-1;
        }

        &::-webkit-scrollbar-track {
          @apply bg-transparent;
        }

        &::-webkit-scrollbar-thumb {
          @apply rounded-sm bg-zinc-400 hover:bg-zinc-500;
        }
      }

      &-link {
        @apply h-auto min-w-48 rounded-sm p-2 font-medium text-zinc-800 hover:bg-theme-200/20 hover:text-theme;

        .active {
          @apply font-bold text-theme after:content-none;
        }
      }
    }
  }
}
