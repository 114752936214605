.ui--notice {
  &-wrapper {
    @apply fixed right-8 top-20;
    z-index: 2000;

    &:not(.active) {
      pointer-events: none !important;
    }
  }

  &-item {
    @apply pointer-events-auto relative ml-auto grid w-fit min-w-64 max-w-96 translate-x-2 overflow-hidden rounded-md opacity-0 ring-2 ring-white/[0.08] backdrop-blur-lg;
    grid-template-columns: 40px 1fr;

    &:not(:last-child) {
      @apply mb-4;
    }
  }

  &-type {
    @apply bg-neutral-900/75;
  }

  &-content {
    @apply bg-neutral-800/75;
  }

  &-close {
  }
}
